import { api } from '..';
import { buildQueryString } from '../utils';
import parseResponse from './parseResponse';
export const thirdPartyChecksExist = projectId => parseResponse(api.thirdPartyChecks.thirdPartyChecksExist(projectId));
export const thirdPartyChecksExistForAlias = (projectId, subjectAliasId) => api.thirdPartyChecks.thirdPartyChecksExistForAlias(projectId, subjectAliasId);
export const thirdPartyChecksExistForSubject = (projectId, subjectId) => api.thirdPartyChecks.thirdPartyChecksExistForSubject(projectId, subjectId);
export const getThirdPartyChecks = projectId => parseResponse(api.thirdPartyChecks.getThirdPartyChecks(projectId));
export const getThirdPartyChecksByRequestId = (projectId, requestId) => parseResponse(api.thirdPartyChecks.getThirdPartyChecksByRequestId(projectId, requestId));
export const runThirdPartyChecks = (projectId, subjectAliasId = null, subjectId = null, typeOfCheck = null) => {
  const queryString = buildQueryString({
    subject_alias_id: subjectAliasId,
    subject_id: subjectId,
    type_of_check: typeOfCheck
  });
  return parseResponse(api.thirdPartyChecks.runThirdPartyChecks(projectId, queryString));
};
export const runThirdPartyChecksForSubject = (projectId, subjectId, typeOfCheck = null) => {
  const queryString = buildQueryString({
    subject_id: subjectId,
    type_of_check: typeOfCheck
  });
  return parseResponse(api.thirdPartyChecks.runThirdPartyChecks(projectId, queryString));
};
export const runThirdPartyChecksForSubjectAlias = (projectId, subjectAliasId, typeOfCheck = null) => {
  const queryString = buildQueryString({
    subject_alias_id: subjectAliasId,
    type_of_check: typeOfCheck
  });
  return parseResponse(api.thirdPartyChecks.runThirdPartyChecks(projectId, queryString));
};
export const createOrUpdateMatch = (projectId, thirdPartyCheckResultUUID, match) => parseResponse(api.thirdPartyChecks.createOrUpdateMatch(projectId, thirdPartyCheckResultUUID, {
  match_type: match
}));
export const markAllAsFalsePositive = (projectId, subjectId, subjectAliasId, typeOfCheck) => {
  return parseResponse(api.thirdPartyChecks.markAllAsFalsePositive(projectId, subjectId, subjectAliasId, typeOfCheck));
};
export const createSummarisation = (projectId, matchType, thirdPartyCheckId, systemPrompt, maxTokens, temperature) => {
  return parseResponse(api.thirdPartyChecks.createSummarisation(projectId, matchType, thirdPartyCheckId, systemPrompt, maxTokens, temperature));
};
export const createSummarisationConfig = config => {
  return parseResponse(api.thirdPartyChecks.createSummarisationConfig(config));
};
export const getSummarisationConfig = () => parseResponse(api.thirdPartyChecks.getSummarisationConfig());